<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="event">
      <v-alert type="warning" v-if="event.notPublished">{{$t('eventmanager.not-published-msg')}}</v-alert>
      <EventHeader :event="event"/>

      <v-card-title class="headline">{{$t('events.welcome.tutorials')}}</v-card-title>
      <v-card-text>These how-to guides might be helpful to get you started in no time!</v-card-text>

      <v-card-title v-if="event.instructions" class="title">{{$t('events.welcome.how-it-works')}}</v-card-title>
      <v-card-text v-if="event.instructions">
        <vue-markdown class="markdown" :html="false">{{ event.instructions }}</vue-markdown>
      </v-card-text>

      <v-row class="mx-3"> 
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Join%20challenge%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Join a Challenge</h3>
            <p>How to join a (step) challenge using our app.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Garmin%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Garmin</h3>
            <p>How to connect with Garmin.</p>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Strava%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Strava</h3>
            <p>How to connect with Strava.</p>
          </v-card>
        </v-col> -->
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Apple%20Watch%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Apple Watch</h3>
            <p>How to connect your Apple Watch.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Connect%20Fitbit%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Connect with Fitbit</h3>
            <p>How to connect your Fitbit.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20Enable%20Step%20Tracking%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Enable Step Tracking</h3>
            <p>How to enable step tracking in the app.</p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card :elevation="1" color="grey lighten-4" class="pa-4" href="https://sodispstoragep.blob.core.windows.net/public/docs/How-to%20-%20QuickEntry%20-%20CorporateFitness.app.pdf">
            <h3 class="my-4"><v-icon class="mr-2">fa-file-pdf</v-icon> Add a Manual Entry</h3>
            <p>How to add a manual entry.</p>
          </v-card>
        </v-col>
      </v-row>      
      <br/>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import EventHeader from '@/components/EventHeader.vue';
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventJoin",
  components: {
    VueMarkdown,
    EventHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
    };
  },

  async mounted() {
    await this.loadEvent(this.$route.params.id);

    EventBus.$on('login-state-change', async user => {
      await this.getEventStatus();
    });


    //test:
    //this.recoverInviteLink();
  },

  methods: {
      
    async getEventStatus() {
      if (this.user) {
      }
    },

    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.$t('events.welcome.tutorials'), disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .markdown-content {
    p { margin-bottom: 0 !important;}
  }
</style>

